<template>
  <div>
    <wrapper-preview-category customClass="section-preview_lk">
      <grid-table
        :data-rows="regions"
        :headers="tableHeaders.regionsMassive"
        :headers-for-rows="tableHeaders.regionsMassive"
        :loading-data="loading"
        :num-cols="tableHeaders.countCells"
        max-table-height="700px"
      >
        <template #regionName="{ row }">
          <div>{{ row.regionName }}</div>
        </template>
        <template #founderStatusRegion="{ row }">
          <div>{{ regionStatus(row) }}</div>
        </template>
        <template #actions="{ row }">
          <button
            v-if="statusCheck(row)"
            class="button"
            style="margin: 0; padding: 2px 5px" @click="revokeByRegionId(row.users.userId)">
            Разблокировать
          </button>
        </template>
      </grid-table>
    </wrapper-preview-category>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import request from "@/services/request";
import WrapperPreviewCategory from "@/components/GridTable/dataFolder/WrapperPreviewCategory";
import AdminQuotaTableHeaders from "@/components/GridTable/dataFolder/AdminQuotaTableHeaders";
import GridTable from "@/components/GridTable/GridTable";

export default {
  name: "AdminRegions",
  components: {GridTable, WrapperPreviewCategory},
  data: () => ({
    loading: false,
    tableHeaders: [],
    regions: [],
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
  },
  
  created() {
    this.getAll();
    this.tableHeaders = AdminQuotaTableHeaders(this);
  },
  methods: {
    async getAll() {
      this.loading = true;
      try {
        const res = await request({
          endpoint: `/api/spr/region/getAll`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          this.regions = res.data;
          console.log('getAll успех');
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    
    async revokeByRegionId(arg) {
      try {
        const res = await request({
          endpoint: `/api/admin/unblock?userId=${arg}`,
          method: 'get',
          token: `${this.getAuthData.token}`,
        });
        if (res.status === 200 && res.data) {
          console.log('revokeByRegionId успех');
          await this.getAll();
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    regionStatus(roiv) {
      if (roiv.users?.userStatus === 'BLOCKED_FOR_PRINT') {
        return 'Заблокирован'
      } else {
        return 'Активен'
      }
    },
    
    statusCheck(row) {
      if (row.users?.userStatus === 'BLOCKED_FOR_PRINT') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="sass">
.yellow-background
  background-color: #fff3cd
  color: #D4990A

.section-preview__header
  font-size: 24px
  font-weight: bold
  margin: 16px 0
  color: #334D6E

.dialog-btn
  .v-btn__content
    padding: 10px 5px

.v-autocomplete
  padding: 0 24px

.v-text-field
  padding: 0 24px
</style>